// stylelint-disable at-rule-no-unknown
@import 'styles/_variables';
@import 'styles/_mixins';

#root,
.AppContainer {
    height: 100%;
    min-height: 100%;

    &--no-scroll {
        overflow: hidden !important;
    }
}

.AppContainer {
    height: calc(100vh);
    min-height: calc(100vh);
    overflow-y: auto !important;

    &__backdrop {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        z-index: $zIndexBackDrop;
        transition: opacity 150ms linear;
        background-color: $color-black;
        animation-name: FadeIn;
        animation-iteration-count: 1;
        animation-duration: 150ms;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        @keyframes FadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 0.5;
            }
        }
    }

    .pt-notifs {
        .notification {
            border-radius: 20px;
        }

        .notification__item {
            border-radius: 5px;
        }

        .notification__message {
            color: black;
            font-family: $font-family-roboto;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: white;
            padding: 15px;
            width: 100%;
            margin: 0;
            max-width: 100%;
            border-radius: 5px;

            img {
                margin-left: 10px;
                margin-right: 20px;
            }
        }

        .notification__link {
            color: $color-link-blue;
        }
    }
}

