@import 'styles/_variables';
@import 'styles/_mixins';

.NoneNegoCheckBox {
    display: flex;
    align-items: center;
    position: relative;

    * p {
        margin: 0;
    }

    &__info-icon {
        width: 15px;
        margin-left: 5px;
        cursor: pointer;
    }

    .checkBox {
        margin: 0;

        &__label-tag {
            padding-left: 25px;
            margin-bottom: 0;
        }
    }

    &__info-details {
        position: absolute;
        width: 440px;
        max-height: 340px;
        overflow-y: auto;
        z-index: -1;
        background: rgba(255, 255, 255, 0);
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 18px;
        color: $color-dark-blue;
        display: none;
        align-items: flex-start;
        border: 1px solid $border-color;
        transition: all 0.3s ease-in-out;
        flex-direction: column;

        &--active {
            background: $bg-color-body;
            z-index: 1000;
            display: flex;
        }

        &--bold {
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
}
