@import 'styles/_variables';
@import 'styles/_mixins';

.profile-menu {
    position: absolute;
    top: 57px;
    left: 0;
    width: 100%;
    height: 100%;
    color: $color-dark-blue;
    z-index: $zIndexBackDrop;

    &__loading {
        height: 10px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        position: relative;
        margin-right: -22px;
        margin-top: 0;
    }

    &__pointer {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 16px solid $color-white;
        margin-top: -24px;
        z-index: $zIndexModal;
        margin-right: 23px;
    }

    &__wrapper {
        background-color: $color-white;
        border-radius: 7px;
        position: relative;
        width: 164px;
        z-index: $zIndexModal;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    &__header {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 10px 0 40px 0;
    }

    &__title {
        font-size: 1rem;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        color: $color-dark-blue;
    }

    &__close-button {
        position: absolute;
        left: 0;
        height: 22px;
        width: 22px;
        border: none;
        transform: scale(1.2);

        @include hover-effect;

        &:focus {
            outline: none;
        }
    }

    &__body {
        display: grid;
        gap: 15px;
    }

    &__row {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 15px;

        @include hover-effect;
    }
}
