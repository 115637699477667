@import 'styles/_variables';
@import 'styles/_mixins';

.inputDiv {
    &__label {
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 0.5008px;
        color: $color-gray-blue;
        display: block;
        margin-bottom: 10px;
        height: 22px;

        &--nomargin {
            margin-bottom: 0;
        }
    }

    &__input {
        background: $color-white;
        border: 0.5008px solid $border-color;
        box-sizing: border-box;
        // box-shadow: inset 0 0 3px $color-brand-blue;
        border-radius: 4px;
        max-width: 420px;
        height: 50px;
        padding: 10px;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 0.5008px;
        color: $color-gray-blue;
        box-shadow: none;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    &__error {
        color: $required;
        display: block;
        margin-top: 5px;
    }

    &__span {
        background: $color-white;
        width: 420px;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 0.5008px;
        color: $form-desc;
        display: block;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-gray-blue;
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $color-gray-blue;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-gray-blue;
    }
}

.inputDiv-label-req {
    color: $required;
    font-weight: bold;
    font-size: 0.9375rem;
}

.phone-input {
    .form-control {
        background: $color-white;
        border: 0.5008px solid $border-color !important;
        box-sizing: border-box;
        // box-shadow: inset 0 0 3px $color-brand-blue;
        border-radius: 4px;
        max-width: 420px !important;
        height: 50px !important;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 0.5008px;
        color: $color-gray-blue;
        width: 100% !important;
    }

    .search-box,
    .country-name {
        color: $color-black;
    }
}

.text-area {
    height: auto;
    resize: none;
}
