@import 'styles/_variables';
@import 'styles/_mixins';

.modal-invite-user {
    width: 400px;

    &__body {
        display: flex;
        flex-direction: column;
        gap: 15px;

        & > .form-row-f {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: $color-dark-blue;
    }

    &__submit {
        font-weight: bold;
        font-size: 0.875rem;
        text-align: center;
        color: $color-white;
        background: $bg-color-button;
        border-radius: 4px;
        border: none;
        height: 50px;
        width: 170px;
        margin-top: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__container {
        flex-direction: column;
        background-color: $color-link;
        padding: 20px 22px;
        gap: 5px;
        border-radius: 3px;
        overflow: hidden;

        p {
            padding: 0;
            margin: 0;
            color: $color-gray-blue;
        }

        &__field-message {
            box-shadow: none;
            border-radius: 4px;
            border: 0.5008px solid $border-color;
            box-sizing: border-box;
            background-color: $color-white;
            padding: 10px;
            width: 100%;

            &:focus {
                outline: none;
            }
        }
    }

    &__submit-container {
        display: flex;
        justify-content: center;
    }

    .form-row {
        gap: 10px;
    }
}
