@import 'styles/_variables';
@import 'styles/_mixins';

.MONotifOnTraderRequestProductAccess {
    &__controls {
        display: flex;
        gap: 10px;

        &__btn {
            @include submit-btn;

            box-shadow: $back-box-shadow;
            color: $color-white;
            padding: 5px 10px;
        }

        &__confirm {
            background: $color-brand-blue;
        }

        &__cancel {
            background: $color-red;
        }
    }
}
